@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

body {
	margin: auto;
	padding: 24px 420px;
	background: #ffffff;
	font-family: "Open Sans";
}

.App {
	/* background-color: #fafafa; */
	padding: 24px;
	border-radius: 8px;
}

.stop-button {
	position: sticky;
	top: 0;
}

.footer {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 18px;
	text-align: center;
	color: #718096;
	margin-top: 24px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

.footer a {
	color: #2563eb;
}

/* Media query for small screens (e.g., smartphones) */
@media (max-width: 767px) {
	/* Styles for small screens */
	body {
		padding: 24px 16px;
	}

	.App {
		padding: 16px;
	}
	/* Other styles for small screens */
}

/* Media query for medium screens (e.g., tablets) */
@media (min-width: 768px) and (max-width: 991px) {
	/* Styles for medium screens */
	body {
		padding: 24px 16px;
	}

	.App {
		padding: 16px;
	}
	/* Other styles for medium screens */
}
